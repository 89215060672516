import * as React from 'react';

import { Props } from './section.interface';
import {
    section,
    section___full,
} from './section.module.scss';

const Section = ({ id, children, colour, full, style }: Props): JSX.Element => {
    const colourStyle = colour ? colour : 'white';
    const fullStyle = full ? section___full : '';

    return (
        <section
            id={id}
            className={`${section} ${fullStyle}`}
            style={{ backgroundColor: colour, ...style }}
        >
            { children }
        </section>
    );
};

export default Section;