
import * as React from 'react';

import { Props } from './page-title.interface';
import {
    pageTitle,
    pageTitle__wrapper,
    pageTitle__button,
} from './page-title.module.scss';

const PageTitle = ({ title, link, text }: Props): JSX.Element => (
    <div className={pageTitle}>
        <div className={pageTitle__wrapper}>
            <h1>{title}</h1>
            { link && (
                <a className={pageTitle__button} href={link} target="_blank">{text}</a>
            )}
        </div>
    </div>
);

export default PageTitle;