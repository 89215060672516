import React, { useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { Form, FormGroup, Label, Input, Alert } from 'reactstrap';

import Button from 'components/button/button';
import { contactForm } from './contact-form.module.scss';

export default (): JSX.Element => {
    return (
        <div className={contactForm}>
            Contact form
        </div>
    );
};
